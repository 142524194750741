import type { NavigationCategory } from '~/composables/types/api/searchDiscover/header'

type GetCategoryByValue = undefined | null | string | number

interface INavigationState {
    loading: boolean
    storage: NavigationCategory | null
    storageAll: NavigationCategory | null
}

interface INavigationActions {
    load: () => void
    getCategoryBy: (key: keyof NavigationCategory, value: GetCategoryByValue) => NavigationCategory | null
}

const compareChildrenBy = (
    children: NavigationCategory[],
    key: keyof NavigationCategory,
    value: any
): NavigationCategory | null => {
    let match = null

    for (const child of children) {
        if (child[key] === value) {
            match = child
            break
        } else if (child.children.length) {
            if (key === 'url' && !value.includes(child.url)) {
                continue
            }

            match = compareChildrenBy(child.children, key, value)

            if (match) {
                break
            }
        }
    }

    return match
}

export default defineStore<string, INavigationState, any, INavigationActions>('navigationStore', {
    state: () => ({
        loading: false,
        storage: null,
        storageAll: null,
    }),

    actions: {
        async load() {
            const { getNavigationCategories, loadNavigation } = useNavigation({
                id: `CATEGORY_TREE_DE_${useStore().isVoStore() ? 'VO' : 'NO'}`,
            })

            if (this.storage) {
                return
            }

            if (this.loading) {
                await new Promise((resolve) => {
                    this.$subscribe((mutation, state) => {
                        if (!state.loading) {
                            resolve(1)
                        }
                    })
                })

                return
            }

            this.loading = true
            await loadNavigation()
            this.loading = false
            this.storage = { ...{}, ...getNavigationCategories() }
            this.storageAll = { ...{}, ...getNavigationCategories(true) }
        },

        getCategoryBy(key: keyof NavigationCategory, value: GetCategoryByValue): NavigationCategory | null {
            if (!this.storageAll || value === undefined || value === null) {
                return null
            }

            return compareChildrenBy([this.storageAll], key, value)
        },
    },
})
